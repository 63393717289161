import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import classNames from "./for.companies.module.scss";
import Button from "../../components/button";
import photo from "../../image/photoPatric.jpg";
import BoxImage from "../../components/slider-images/box-image";
import CheckCircleIcon from "../../icons/for-companies/check-circle";
import FaqQuestion from "../../components/faq-question";
import ContactUs from "../../components/contact-us";
import Footer from "../../components/footer";
import CompaniesForDeliver from "../../components/companies-for-deliver";
import AOS from "aos";
import "aos/dist/aos.css";
import { ANIMATION_TYPE } from "../../constants/animation";
import { BENEFITS_LIST } from "src/constants/for_companies";
import { FAQ_QUESTIONS_FOR_COMPANY } from "../../constants/for_companies";
import { KEY_ACCEPTED_COOKIES } from "../../constants/homepage";
import { getAcceptedCookie } from "../../services/acceptedCookie";
import CookieBanner from "../../components/cookie-banner/index";
function ForCompanies() {
  const [isAcceptedCookie, setIsAcceptedCookie] = useState(
    getAcceptedCookie(KEY_ACCEPTED_COOKIES)
  );
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <main>
      <section className={classNames.wrapper}>
        <Header showDayTime showFacebookIcon showNav page="for-companies" />
      </section>
      <section className={classNames.wrapper} data-aos={ANIMATION_TYPE}>
        <h1 className={classNames.title}>Pre firmy</h1>
        <p className={classNames.description}>
          Kuriérska firma doruč.to v Košiciach a okolí pre našich partnerov
          zabezpečuje kuriérske služby s doručením v deň objednávky za ceny
          nižšie ako pri štandardnom doručení zásielok. Pre viac info o našich
          službách alebo cenníku nás kontaktujte na:
        </p>
        <div className={classNames.contact}>
          <Button className={classNames.emailBtn} sm color={"green"}>
              spolupraca@doruc.to
          </Button>
          <div className={classNames.info}>
            <div>
              <img src={photo} className={classNames.photo} />
            </div>
            <div className={classNames.data}>
              <strong>Patrik Preščák </strong>
                spolupraca@doruc.to +421 907 903 798
            </div>
          </div>
        </div>
      </section>
      <div className={classNames.benefits} data-aos={ANIMATION_TYPE}>
        <div className={classNames.box}>
          <BoxImage />
        </div>
        <div className={classNames.wrapper}>
          <h2 className={classNames.subtitle}>Výhody pre firmy</h2>
          <ul className={classNames.list}>
            {BENEFITS_LIST.map((item, index) => (
              <li className={classNames.item} key={index}>
                <div className={classNames.itemIcon}>
                  <CheckCircleIcon />
                </div>
                <div>{item}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <section data-aos={ANIMATION_TYPE}>
        <CompaniesForDeliver
          className={classNames.companiesForDelivery}
          title="Firmy, pre ktoré doručujeme"
        />
      </section>
      <section data-aos={ANIMATION_TYPE}>
        <FaqQuestion
          typeQuestions={FAQ_QUESTIONS_FOR_COMPANY}
          tabs={false}
          title="Časté otázky pre firmy"
          showMore={true}
        />
      </section>
      <section data-aos={ANIMATION_TYPE}>
        <ContactUs />
      </section>
      <Footer />
      {isAcceptedCookie === null ? (
        <CookieBanner setIsAcceptedCookie={setIsAcceptedCookie} />
      ) : (
        ""
      )}
    </main>
  );
}

export default ForCompanies;
